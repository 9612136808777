import React from "react"
import { Flex, Text } from "theme-ui"
import { useAccountContext } from "../../../context/AccountContext"
import Layout from "../../layouts"
import AccountLayout from "../../layouts/account-layout"
import { useTranslation } from "react-i18next"
import TileSection from "../../new-ui/tile-section"
import useWindowSize from "../../../hooks/useWindowSize"

const WelcomeBack = ({ data }) => {
  const { firstName } = useAccountContext()
  const { t } = useTranslation()
  const { width } = useWindowSize()

  return (
    <Layout hideFooter={true} navbarTransparent={width > 768}>
      <AccountLayout>
        <Flex
          sx={{
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Text
            as="h1"
            mb="24px"
            sx={{
              fontSize: "21px",
              fontWeight: "normal",
              display: "flex",
            }}
          >
            {t("welcome_back")} {firstName}
          </Text>
          <TileSection
            tiles={data.tileSection.tiles}
            sx={{ flex: 1, gap: 2 }}
            tileSx={{ flex: 1, height: "fit-content" }}
            {...data.tileSection}
          />
        </Flex>
      </AccountLayout>
    </Layout>
  )
}

export default WelcomeBack
